import Header from "@/components/Header/Header";
import HeaderTop from "@/components/Header/HeaderTop/Header-Top";
import PopupMobileMenu from "@/components/Header/PopupMobileMenu";
import Home from "@/components/Home/Home";

import Applications from "@/components/Home/Applications";
import Partnerships from "@/components/Home/Partnerships";
import HowItWorks from "@/components/Home/HowItWorks";
import Solutions from "@/components/Home/Solutions";
import Agents from "@/components/Home/Agents";
import Pricing from "@/components/Home/Pricing";

import Context from "@/context/Context";
import Footer from "@/components/Footer/Footer";
import Copyright from "@/components/Footer/Copyright";

import Separator from "../separator";
import PageHead from "../Head";

import HomeData from "../../data/home.json";

const HomePage = () => {
  return (
    <>
      <PageHead title="Home" />
      <main className="page-wrapper">
        <Context>
          <HeaderTop />
          <Header
            headerTransparent="header-not-transparent"
            headerSticky="header-sticky"
            btnClass="btn-small round"
          />
          <PopupMobileMenu />
          <Home />
          <div className="mb--200">
            <Applications data={HomeData?.applications} />
          </div>
          <div className="mb--200">
            <Applications data={HomeData?.applications2} />
          </div>
          <div className="mb--200" id="partnerships">
            <Applications data={HomeData?.applications3} />
          </div>
          <Separator top={true} />
          <Partnerships />
          <Separator top={true} />
          <div id="howitworks">
            <HowItWorks />
          </div>
          <Separator top={true} />
          <div id="solutions">
            <Solutions />
          </div>
          <Separator top={true} />
          <Agents />
          <Separator top={true} />
          <div id="pricing">
            <Pricing />
          </div>
          <Footer />
          <Copyright />
        </Context>
      </main>
    </>
  );
};

export default HomePage;
