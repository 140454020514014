import React, { useEffect } from "react";
import Image from "next/image";
import Link from "next/link";

import sal from "sal.js";

import AppConfigs from "@/configs";

function Applications({ data }) {
  useEffect(() => {
    sal();
  }, []);

  return (
    <>
      <div className="rainbow-callto-action-area">
        <div className="wrapper">
          <div className="fancy-genearate-section">
            <div className="container">
              <div className="genarator-section">
                <ul className="genarator-card-group full-width-list">
                  {data &&
                    data.slice(0, 4).map((data, index) => (
                      <li key={index}>
                        <Link
                          target="_blank"
                          href={`${AppConfigs.WEB_APP_URL}/app/view?id=${data.appId}`}
                          className="genarator-card bg-flashlight-static center-align"
                          style={{
                            display: "flex",
                            flex: 1,
                            height: "100%",
                            paddingTop: 40,
                            paddingBottom: 40,
                          }}
                        >
                          <div className="inner" style={{ padding: 0 }}>
                            <div
                              style={{
                                padding: 0,
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                                flex: 1,
                                height: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  flexDirection: "column",
                                  flex: 1,
                                }}
                              >
                                <div
                                  className="img-bar"
                                  style={{
                                    width: 100,
                                    height: 100,
                                    backgroundColor: "white",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: 10,
                                  }}
                                >
                                  <Image
                                    src={data.img}
                                    width={80}
                                    height={80}
                                    alt="AI Generator"
                                    style={{ objectFit: "contain", backgroundColor: "white" }}
                                  />
                                </div>
                                <div>
                                  <h5
                                    className="title"
                                    style={{ textAlign: "center", marginTop: 10 }}
                                  >
                                    {data.title}
                                  </h5>
                                  <p
                                    data-sal="slide-up"
                                    style={{ textAlign: "center", marginTop: 10 }}
                                  >
                                    {data.desc}
                                  </p>
                                </div>
                              </div>
                              <span
                                className="rainbow-demo-btn"
                                style={{ textAlign: "center" }}
                                onClick={() => {
                                  window
                                    .open(
                                      `${AppConfigs.WEB_APP_URL}/app/view?id=${data.appId}`,
                                      "_blank"
                                    )
                                    .focus();
                                }}
                              >
                                Try It Now Free
                              </span>
                            </div>
                          </div>
                          {data.badge !== "" ? (
                            <span className="rainbow-badge-card">{data.badge}</span>
                          ) : (
                            ""
                          )}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Applications;
