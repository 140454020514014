import Image from "next/image";

import splitImg from "../../public/images/split/split-8.webp";
import HomeData from "../../data/home.json";

const Agents = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <Image className="radius" src={splitImg} alt="split Images" />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner" style={{ paddingRight: 0 }}>
                    <h4
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      Craft virtual agents to boost your business and work
                    </h4>
                    <div className="row">
                      {HomeData.agents.map((data, index) => (
                        <div key={`_${index}`} className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <h5 data-sal="slide-up" data-sal-duration="400" data-sal-delay="300">
                            {data.title}
                          </h5>
                          <ul
                            className="split-list"
                            data-sal="slide-up"
                            data-sal-duration="400"
                            data-sal-delay="350"
                          >
                            {data.items.map((item, index) => (
                              <li key={`${item}_${index}`}>{`- ${item}`}</li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Agents;
